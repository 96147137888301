<template>
  <div class="content">
    <div class="content_top">
      <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>
      <p>{{ this.hotelData.name ? this.hotelData.name : '--' }}</p>
    </div>
    <!-- 评分 -->
    <div class="comment">
      <div class="score">
        <span>
          {{ commentStatistics.score.toFixed(1) }}
        </span>
        <span>/5.0</span>
      </div>
      <div class="other">
        <div class="other_scores">
          <span>卫生 {{ commentStatistics.sanitation_score.toFixed(1) }}</span>
          <el-progress :show-text=false :percentage="conversionScore(commentStatistics.sanitation_score)"></el-progress>
        </div>
        <div class="other_score">
          <span> 环境 {{ commentStatistics.env_score.toFixed(1) }}</span>
          <el-progress :show-text=false :percentage="conversionScore(commentStatistics.env_score)"></el-progress>
        </div>
        <div class="other_score">
          <span>服务 {{ commentStatistics.service_score.toFixed(1) }}</span>
          <el-progress :show-text=false :percentage="conversionScore(commentStatistics.service_score)"></el-progress>
        </div>
        <div class="other_score">
          <span> 设施 {{ commentStatistics.facility_score.toFixed(1) }}</span>
          <el-progress :show-text=false :percentage="conversionScore(commentStatistics.facility_score)"></el-progress>
        </div>
      </div>
    </div>

    <!-- 评论数量 -->
    <div class="comment_num">
      <div class="comment_num_item" :class="{ 'choose_comment_num_item': selectComment === 1 }"
        @click="selectComment = 1">
        <div>全部点评</div>
        <div><span>{{ commentStatistics.total }}</span> 条</div>
        <div>好评率： {{ commentStatistics.good_rate }}</div>
      </div>
      <div class="comment_num_item" :class="{ 'choose_comment_num_item': selectComment === 2 }"
        @click="selectComment = 2">
        <div>待回复</div>
        <div><span>{{ commentStatistics.reply }}</span> 条</div>
        <div>回复率：{{ commentStatistics.reply_rate }}</div>
      </div>
      <div class="comment_num_item" :class="{ 'choose_comment_num_item': selectComment === 3 }"
        @click="selectComment = 3">
        <div>差评率</div>
        <div><span>{{ commentStatistics.bad }}</span> 条</div>
        <div>好评率：{{ commentStatistics.good_rate }}</div>
      </div>
    </div>
    <!-- <div class="comment_search">
      <el-date-picker v-model="screen_time" type="daterange" value-format="timestamp" start-placeholder="点评开始日"
        end-placeholder="点评结束日" :unlink-panels="true" size="small"></el-date-picker>
      <el-input placeholder="关键词" size="small" v-model="screen_Comment"></el-input>
      <el-button type="primary" size="small">筛选</el-button>
      <el-button size="small">重置</el-button>
    </div> -->
    <!-- 信息 -->
    <div class="comment_detail">
      <div class="comment_detail_item" v-for="(item, index) in  commentList " :key="index">
        <div class="comment_detail_item_left">
          <div>{{ item.user }}</div>
          <div>{{ item.create_time }}</div>
          <div> {{ item.sub_product_name }}</div>
        </div>
        <div class="comment_detail_item_right">
          <div class="top">
            <span><span>{{ item.score }}</span> 总分</span>
            <span>环境 {{ item.env_score }}</span>
            <span>设施 {{ item.facility_score }}</span>
            <span>服务 {{ item.service_score }}</span>
            <span>卫生 {{ item.sanitation_score }}</span>
          </div>
          <div class="text">{{ item.comment }}</div>
          <div class="time">发表于：2022-09-17 23:25:31</div>
          <div class="pic">
            <div v-for="(pic, picIndex) in item.media_ids" :key="picIndex">
              <img :src="pic" />
            </div>
          </div>
          <div class="reply">
            <div>商家回复内容:</div>
            <div>
              <!-- is_has_reply 0未回复  1有回复-->
              <p v-if="!item.isEdit && item.is_has_reply == 1"> {{ item.reply_list.content }}</p>
              <el-input v-if="item.isEdit && item.is_has_reply == 1" type="textarea" :rows="4" placeholder="请输入内容"
                v-model="item.reply_list.content">
              </el-input>
              <p v-if="!item.isEdit && item.is_has_reply == 0" style="color: #cccccc;">回复顾客评价，与顾客互动促进提升好感</p>
              <el-input v-if="item.isEdit && item.is_has_reply == 0" type="textarea" :rows="4" placeholder="请输入内容"
                v-model="replyContent">
              </el-input>
            </div>
            <div>
              <div>
                <div v-if="item.is_has_reply == 1">
                  <span>发表于：{{ item.reply_list.create_time }} </span>
                  <span>回复员工：{{ item.reply_list.reply_user }} </span>
                </div>
              </div>
              <div>
                <template v-if="!item.isEdit && item.is_has_reply != 0">
                  <el-button type="text" size="small" @click="item.isEdit = true">编辑</el-button>
                  <el-button type="text" size="small" @click="handleDeleteComment(item)">删除</el-button>
                </template>
                <el-button type="text" v-if="item.isEdit && item.is_has_reply != 0" size="small"
                  @click="handleEditComment(item)">保存</el-button>
                <el-button type="text" v-if="item.isEdit" size="small" @click="handleCancel(item)">取消</el-button>

                <el-button type="text" v-if="!item.isEdit && item.is_has_reply == 0" size="small"
                  @click="item.isEdit = true">回复</el-button>
                <el-button type="text" v-if="item.isEdit && item.is_has_reply == 0" size="small"
                  @click="handleConfirmReply(item)">确认</el-button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <pagination :data="pageNation_data" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </pagination>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../components/PaginationPage/Pagination.vue'
export default {
  data() {
    return {
      selectComment: 1,//1全部点评 2待回复 3差评率
      screen_Comment: null,//搜索关键词
      screen_time: null,//搜索 日期
      hotelData: null,//当前酒店信息
      commentList: [],//评论列表
      commentStatistics: null,//评论数据汇总
      // 传递分页组件的数据
      pageNation_data: {
        total: 0,
        currentPage: 1,
        currentSize: 10,
        small: true
      },

      replyContent: '',//回复内容
    }
  },
  created() {

    // 获取传递过来的酒店信息
    const hotelData = JSON.parse(this.$route.query.hotelData);
    this.hotelData = hotelData
    // 现在，hotelData 中就包含了传递过来的信息
    console.log('hotelData', hotelData);

    this.get_commentList()
    this.get_commentStatistics()
  },
  mounted() {

  },
  methods: {
    // 取消
    handleCancel(item) {
      item.isEdit = false
      this.replyContent = ''
      this.get_commentList()
    },
    // 回复评论
    handleConfirmReply(item) {
      console.log('回复评论', item)
      let data = {
        id: item.id,
        content: this.replyContent
      }
      console.log('回复data，', data)
      this.my_request('admin/reply', data, 'POST').then(res => {
        console.log('回复结果，', res)
        let { code, message } = res.data
        if (200 == code) {
          this.$message({
            type: 'success',
            message: '保存成功！'
          });
          this.get_commentList()
        } else {
          this.$message({
            type: 'errpor',
            message: '保存失败'
          });
        }
        item.isEdit = false
      })
    },
    // 保存评论
    handleEditComment(item) {
      console.log('item', item)
      //编辑保存
      let data = {
        id: item.reply_list.id,
        content: item.reply_list.content
      }
      console.log('修改回复data，', data)
      this.my_request('admin/reply', data, 'PUT').then(res => {
        console.log('修改回复结果，', res)
        let { code, message } = res.data
        if (200 == code) {
          this.$message({
            type: 'success',
            message: '保存成功！'
          });
        } else {
          this.$message({
            type: 'errpor',
            message: '保存失败'
          });
        }
        item.isEdit = false
        this.get_commentList()
      })
    },
    // 删除评论
    handleDeleteComment(item) {
      console.log('item', item)
      let data = {
        id: item.reply_list.id,
      }
      console.log('删除评论data，', data)
      this.my_request('admin/reply', data, 'DELETE').then(res => {
        console.log('删除评论结果，', res)
        let { code, message } = res.data
        if (200 == code) {
          this.$message({
            type: 'success',
            message: '保删成功！'
          });
        } else {
          this.$message({
            type: 'errpor',
            message: '删除失败'
          });
        }
        this.get_commentList()
      })
    },


    // 转换评分为1-100的数字 用于进度条
    conversionScore(score) {
      let data = (score / 5.0) * 100
      return data
    },
    // 分页组件事件
    handleSizeChange(val) {
      // 处理每页数量改变的逻辑
      console.log(`每页 ${val} 条`);
      this.pageNation_data.currentSize = val
      this.get_commentList()
    },
    handleCurrentChange(val) {
      // 处理当前页码改变的逻辑
      console.log(`当前页: ${val}`);
      this.pageNation_data.currentPage = val
      this.get_commentList()
    },

    // 获取评论数据汇总
    get_commentStatistics() {
      let data = {
        id: this.hotelData.product_id,
      }
      console.log('获取获取评论数据汇总data', data)
      this.my_request('admin/comment/statistics', data, 'GET').then(res => {
        console.log('获取获取评论数据汇总结果', res)
        let { code, data } = res.data
        if (200 == code) {
          this.commentStatistics = data

        }
      })
    },
    // 获取产品评论列表
    get_commentList() {
      let data = {
        id: this.hotelData.product_id,
        page: this.pageNation_data.currentPage,
        page_rows: this.pageNation_data.currentSize,
      }
      console.log('获取产品评论列表data', data)
      this.my_request('admin/comment/list', data, 'GET').then(res => {
        console.log('获取产品评论列表结果', res)
        let { code, data } = res.data
        if (200 == code) {
          this.commentList = data.list.map(item => {
            item.isEdit = false
            return item
          })
          this.pageNation_data.total = data.total
        }
      })
    },

    // 返回酒店评论列表
    goBack() {
      this.$router.push({ path: '/comment/comment_hotel' })
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  overflow: hidden;

  .pagination {
    margin-bottom: 20px;
  }

  .content_top {
    display: flex;
    align-items: center;
    height: 40px;

    p {
      margin-left: 10px;
      font-weight: bold;
    }
  }

  .comment {
    height: 80px;
    display: flex;
    align-items: center;
    margin: 20px 40px 50px 40px;

    .score {
      color: #409eff;
      margin-right: 50px;

      span:nth-child(1) {
        font-size: 50px;
      }
    }

    .other {
      display: flex;
      flex-wrap: wrap;
      width: 400px;
      justify-content: space-between;

      .other_scores {
        margin-bottom: 20px;
      }

      .el-progress {
        width: 150px;
        margin-top: 6px;
        margin-left: 10px;
        text-align: center;
        align-content: center;
      }
    }

  }

  .comment_num {
    padding: 20px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    .comment_num_item {
      width: 25%;
      height: 80px;
      padding: 20px;
      border-radius: 8px;
      background-color: white;
      border: 1px solid transparent;

      div {
        line-height: 25px;
      }

      div:nth-child(2) {
        span {
          font-size: 25px;
        }
      }
    }

    .choose_comment_num_item {
      background-color: #EDF4FF;
      border: 1px solid #999999;
    }
  }

  .comment_search {
    display: flex;
    margin: 20px;

    .el-range-editor--small.el-input__inner {
      width: 260px;
    }

    .el-input {
      width: 180px;
      margin: 0 10px;
    }
  }

  .comment_detail {
    width: 100%;

    .comment_detail_item {
      display: flex;
      font-size: 14px;
      padding: 20px 0;

      .comment_detail_item_right {
        display: inline-block;
        width: 80%;

        .top {
          font-weight: bold;
          font-size: 16px;

          span {
            margin-left: 15px;
          }

          span:nth-child(1) {
            margin-left: 0px;

            span {
              font-size: 24px;
            }

          }
        }

        .text {
          margin-top: 20px;
        }

        .time {
          color: #999999;
          margin: 15px 0;
        }

        .pic {
          display: flex;

          div {
            margin-right: 10px;
            width: 88px;
            height: 88px;
            background-color: #d9d9d9;
            border-radius: 10px;
            overflow: hidden;
          }
        }

        .reply {
          margin-top: 15px;
          padding: 20px;
          background-color: #f4f8fb;
          border-radius: 10px;
          font-size: 12px;

          div:nth-child(2) {
            font-size: 14px;
            margin: 10px 0;
          }

          div:nth-child(3) {
            display: flex;
            justify-content: space-between;

            div:nth-child(2) {
              margin: 0;
            }
          }
        }
      }

      .comment_detail_item_left {
        width: 14%;
        margin-right: 2%;
        font-weight: bold;
        font-size: 14px;

        div:nth-child(2) {
          margin: 8px 0 12px 0;
        }
      }
    }
  }

}
</style>
